import {
  Button,
  Calendar,
  Card,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import LabelWithPopover from "../../../../../common/components/form/labels/LabelWithPopover";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { ModalSizes, rowGutter } from "../../../../../common/constants";
import ComponentWithPermission from "../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../common/types";
import { formatAgentName, formatLocaleCurrency, formatLocaleDate } from "../../../../../common/utils/formatUtils";
import {
  datePickerDefaultRanges,
  datePickerDefaultRangesUntilToday,
  dateToIsoDateString,
  disableDatePickerFuture,
  getDatePickerFormat,
  inputNumberIntegerStandardProps,
  mapInstitutionTreeSelectValuesToInstitutionIds,
  mapProductTreeSelectValuesToProductIds,
  rangePickerFormItemProps,
  resolveFormValidationError,
  selectTagsStandardProps
} from "../../../../../common/utils/formUtils";
import { getClosestPreviousWorkDay } from "../../../../../common/utils/utils";
import { validationConstants, validations } from "../../../../../common/utils/validationUtils";
import { AffiliatePartnerBase } from "../../../../affiliate/types";
import { AgentType } from "../../../../agent/enums";
import AffiliatePartnerSelect from "../../../../enumerations/components/form/AffiliatePartnerSelect";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import InstitutionTreeSelect from "../../../../enumerations/components/form/InstitutionTreeSelect";
import ProductTreeSelect from "../../../../enumerations/components/form/ProductTreeSelect";
import {
  selectAffiliatePartnersEnums,
  selectInstitutionsEnums,
  selectProductGroupsEnums,
  selectSubordinateAgentsEnums
} from "../../../../enumerations/ducks";
import { AgentEnumeration, InstitutionWithSettings, ProductGroupWithProducts } from "../../../../enumerations/types";
import { downloadContractsVerificationExportActions } from "../../../ducks";
import {
  ContractStatus,
  contractStatusTMap,
  ContractVerificationStatus,
  contractVerificationStatusTMap,
  ContractView
} from "../../../enums";
import { ContractFilterPageRequest, ContractFilterPageResult } from "../../../types";
import { INSURANCE_CONTRACT_CANCELLING_TERM_DAYS } from "../../../utils";
import ContractStatusTag from "../../ContractStatusTag";
import ContractVerificationStatusTag from "../../ContractVerificationStatusTag";

interface Props {
  contractsPage: ContractFilterPageResult;
  onFilterSubmit: (filter: ContractFilterPageRequest) => void;
  onRecalculatePredictedCommissionsClick: () => void;
  onVerificationExportDownload: typeof downloadContractsVerificationExportActions.request;
}

const ContractListFilterView = ({
  contractsPage,
  onFilterSubmit,
  onRecalculatePredictedCommissionsClick,
  onVerificationExportDownload
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ContractFilterPageRequest>();

  const subordinateAgentsEnums = useSelector<RootState, AgentEnumeration[]>(selectSubordinateAgentsEnums);
  const institutionsEnums = useSelector<RootState, InstitutionWithSettings[]>(selectInstitutionsEnums);
  const productGroupsEnums = useSelector<RootState, ProductGroupWithProducts[]>(selectProductGroupsEnums);
  const affiliatePartnersEnums = useSelector<RootState, AffiliatePartnerBase[]>(selectAffiliatePartnersEnums);

  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [filterModalText, setFilterModalText] = useState<string>();
  const [isCalcFixationAnniversaryModalOpen, setIsCalcFixationAnniversaryModalOpen] = useState<boolean>(false);
  const [calcFixationAnniversaryDate, setCalcFixationAnniversaryDate] = useState<string>();

  useEffect(() => {
    setFilterModalText(modalFilterToText({ ...contractsPage }));
  }, [contractsPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(
        ({
          createdAtRange,
          mediationReportSignDateRange,
          effectiveBeginningDateOrSignDateRange,
          effectiveEndDateOrLoanMaturityDateRange,
          cancellationDateRange,
          transferredToOtherBrokerDateRange,
          vehicleFirstRegistrationYearRange,
          ...values
        }) => {
          onFilterSubmit({
            ...values,
            institutionIds: mapInstitutionTreeSelectValuesToInstitutionIds(
              values.institutionIds ?? [],
              institutionsEnums
            ),
            productIds: mapProductTreeSelectValuesToProductIds(values.productIds ?? [], productGroupsEnums),
            createdAtMin: createdAtRange?.[0],
            createdAtMax: createdAtRange?.[1],
            mediationReportSignDateMin: mediationReportSignDateRange?.[0],
            mediationReportSignDateMax: mediationReportSignDateRange?.[1],
            effectiveBeginningDateOrSignDateMin: effectiveBeginningDateOrSignDateRange?.[0],
            effectiveBeginningDateOrSignDateMax: effectiveBeginningDateOrSignDateRange?.[1],
            effectiveEndDateOrLoanMaturityDateMin: effectiveEndDateOrLoanMaturityDateRange?.[0],
            effectiveEndDateOrLoanMaturityDateMax: effectiveEndDateOrLoanMaturityDateRange?.[1],
            cancellationDateMin: cancellationDateRange?.[0],
            cancellationDateMax: cancellationDateRange?.[1],
            transferredFromOtherBroker: values.transferredFromOtherBroker,
            transferredToOtherBrokerDateMin: transferredToOtherBrokerDateRange?.[0],
            transferredToOtherBrokerDateMax: transferredToOtherBrokerDateRange?.[1],
            vehicleFirstRegistrationYearMin: vehicleFirstRegistrationYearRange?.[0],
            vehicleFirstRegistrationYearMax: vehicleFirstRegistrationYearRange?.[1]
          });

          setFilterModalOpen(false);
        }
      )
      .catch(resolveFormValidationError);
  };

  const handleModalCancel = (): void => {
    form.resetFields([
      ["createdAtRange"],
      ["mediationReportSignDateRange"],
      ["effectiveBeginningDateOrSignDateRange"],
      ["effectiveEndDateOrLoanMaturityDateRange"],
      ["cancellationDateRange"],
      ["transferredFromOtherBroker"],
      ["transferredToOtherBrokerDateRange"],
      ["annualPremiumOrApprovedAmountMin"],
      ["annualPremiumOrApprovedAmountMax"],
      ["vehicleFirstRegistrationYearRange"],
      ["affiliatePartnerIds"]
    ]);
    setFilterModalOpen(false);
  };

  const clearModalFilterFields = (): void => {
    form.setFieldsValue({
      signerIds: [],
      managerIds: [],
      createdAtRange: undefined,
      mediationReportSignDateRange: undefined,
      effectiveBeginningDateOrSignDateRange: undefined,
      effectiveEndDateOrLoanMaturityDateRange: undefined,
      cancellationDateRange: undefined,
      transferredFromOtherBroker: false,
      transferredToOtherBrokerDateRange: undefined,
      annualPremiumOrApprovedAmountMin: undefined,
      annualPremiumOrApprovedAmountMax: undefined,
      vehicleFirstRegistrationYearRange: undefined,
      affiliatePartnerIds: []
    });
  };

  const clearMainFilterFields = (): void => {
    form.setFieldsValue({
      keyword: undefined,
      institutionIds: [],
      productIds: [],
      gainerIds: [],
      statuses: [],
      verificationStatuses: []
    });
  };

  const handleModalFilterClear = (): void => {
    clearModalFilterFields();
    handleFormSubmit();
  };

  const handleFilterClear = (): void => {
    clearModalFilterFields();
    clearMainFilterFields();
    handleFormSubmit();
  };

  const modalFilterToText = (filter: ContractFilterPageRequest): string => {
    const formatDateRange = (label: string, min: string, max: string): string => {
      return `${label}: ${formatLocaleDate(min)} ~ ${formatLocaleDate(max)}`;
    };
    const params = [];

    if (filter.signerIds?.length) {
      params.push(
        `${t("contract.filter.signerIds")}: ${filter.signerIds
          .map(id => subordinateAgentsEnums.find(agent => agent.id === id))
          .filter(agent => !!agent)
          .map(agent => formatAgentName(agent))
          .join(", ")}`
      );
    }

    if (filter.managerIds?.length) {
      params.push(
        `${t("contract.filter.managerIds")}: ${filter.managerIds
          .map(id => subordinateAgentsEnums.find(agent => agent.id === id))
          .filter(agent => !!agent)
          .map(agent => formatAgentName(agent))
          .join(", ")}`
      );
    }

    if (filter.createdAtMin && filter.createdAtMax) {
      params.push(formatDateRange(t("contract.filter.createdAtRange"), filter.createdAtMin, filter.createdAtMax));
    }

    if (filter.mediationReportSignDateMin && filter.mediationReportSignDateMax) {
      params.push(
        formatDateRange(
          t("contract.filter.mediationReportSignDateRange"),
          filter.mediationReportSignDateMin,
          filter.mediationReportSignDateMax
        )
      );
    }

    if (filter.effectiveBeginningDateOrSignDateMin && filter.effectiveBeginningDateOrSignDateMax) {
      params.push(
        formatDateRange(
          t("contract.filter.effectiveBeginningDateOrSignDateRange"),
          filter.effectiveBeginningDateOrSignDateMin,
          filter.effectiveBeginningDateOrSignDateMax
        )
      );
    }

    if (filter.effectiveEndDateOrLoanMaturityDateMin && filter.effectiveEndDateOrLoanMaturityDateMax) {
      params.push(
        formatDateRange(
          t("contract.filter.effectiveEndDateOrLoanMaturityDateRange"),
          filter.effectiveEndDateOrLoanMaturityDateMin,
          filter.effectiveEndDateOrLoanMaturityDateMax
        )
      );
    }

    if (filter.cancellationDateMin && filter.cancellationDateMax) {
      params.push(
        formatDateRange(
          t("contract.filter.cancellationDateRange"),
          filter.cancellationDateMin,
          filter.cancellationDateMax
        )
      );
    }

    if (filter.transferredFromOtherBroker) {
      params.push(t("contract.filter.transferredFromOtherBroker"));
    }

    if (filter.transferredToOtherBrokerDateMin && filter.transferredToOtherBrokerDateMax) {
      params.push(
        formatDateRange(
          t("contract.filter.transferredToOtherBrokerDateRange"),
          filter.transferredToOtherBrokerDateMin,
          filter.transferredToOtherBrokerDateMax
        )
      );
    }

    if (filter.vehicleFirstRegistrationYearMin && filter.vehicleFirstRegistrationYearMax) {
      params.push(
        `${t("contract.filter.vehicleFirstRegistrationYearRange")}: ${filter.vehicleFirstRegistrationYearMin} ~ ${
          filter.vehicleFirstRegistrationYearMax
        }`
      );
    }

    if (filter.annualPremiumOrApprovedAmountMin && filter.annualPremiumOrApprovedAmountMax) {
      params.push(
        `${t("contract.filter.annualPremiumOrApprovedAmountRange")}: ${formatLocaleCurrency(
          filter.annualPremiumOrApprovedAmountMin
        )} ~ ${formatLocaleCurrency(filter.annualPremiumOrApprovedAmountMax)}`
      );
    } else if (filter.annualPremiumOrApprovedAmountMin) {
      params.push(
        `${t("contract.filter.attrs.annualPremiumOrApprovedAmountMin")}: ${formatLocaleCurrency(
          filter.annualPremiumOrApprovedAmountMin
        )}`
      );
    } else if (filter.annualPremiumOrApprovedAmountMax) {
      params.push(
        `${t("contract.filter.attrs.annualPremiumOrApprovedAmountMax")}: ${formatLocaleCurrency(
          filter.annualPremiumOrApprovedAmountMax
        )}`
      );
    }

    if (filter.affiliatePartnerIds?.length) {
      params.push(
        `${t("contract.filter.affiliatePartners")}: ${filter.affiliatePartnerIds
          .map(id => affiliatePartnersEnums.find(ap => ap.id === id)?.name)
          .join(", ")}`
      );
    }

    return params.join(" | ");
  };

  const colSpan = 4;
  const smallColSpan = 3;

  return (
    <>
      <Card
        className="card-box"
        title={<h2>{t("contract.titles.list")}</h2>}
        extra={
          <>
            {contractsPage.report === ContractView.INTERNAL_GENERIC_REPORT && (
              <ComponentWithPermission permissions={[Permission.CONTRACT_VERIFICATION_EXPORT]}>
                <ActionButton
                  icon="cloud-download"
                  color="green"
                  variant="outlined"
                  size="middle"
                  onClick={() => onVerificationExportDownload()}
                >
                  {t("contract.actions.verificationExportDownload")}
                </ActionButton>
              </ComponentWithPermission>
            )}

            {contractsPage.report === ContractView.INTERNAL_PREDICTED_COMMISSIONS_REPORT && (
              <ComponentWithPermission permissions={[Permission.COMMISSIONS_MANAGE]}>
                <ActionButton
                  icon="euro"
                  color="orange"
                  variant="outlined"
                  size="middle"
                  onClick={onRecalculatePredictedCommissionsClick}
                >
                  {t("contract.actions.recalculatePredictedCommissions")}
                </ActionButton>
              </ComponentWithPermission>
            )}

            {contractsPage.report === ContractView.INTERNAL_ANNIVERSARY_DATE_REPORT ? (
              <>
                <ActionButton
                  icon="calculator"
                  color="green"
                  variant="outlined"
                  size="middle"
                  onClick={() => setIsCalcFixationAnniversaryModalOpen(true)}
                >
                  {t("contract.actions.calculateFixationAnniversaryDate")}
                </ActionButton>

                <Modal
                  width={ModalSizes.SMALL}
                  open={isCalcFixationAnniversaryModalOpen}
                  title={t("contract.actions.calculateFixationAnniversaryDate")}
                  onCancel={() => setIsCalcFixationAnniversaryModalOpen(false)}
                  afterClose={() => setCalcFixationAnniversaryDate(undefined)}
                  footer={[
                    <Button key="close" type="primary" onClick={() => setIsCalcFixationAnniversaryModalOpen(false)}>
                      {t("common.close")}
                    </Button>
                  ]}
                >
                  <Calendar
                    fullscreen={false}
                    value={dayjs(calcFixationAnniversaryDate)}
                    onChange={value => setCalcFixationAnniversaryDate(dateToIsoDateString(value))}
                  />
                  <p style={{ marginTop: 16, textAlign: "center" }}>
                    <div>
                      {calcFixationAnniversaryDate ? (
                        <div style={{ fontWeight: 500, fontSize: 24 }}>
                          {formatLocaleDate(
                            getClosestPreviousWorkDay(
                              dayjs(calcFixationAnniversaryDate).subtract(
                                INSURANCE_CONTRACT_CANCELLING_TERM_DAYS,
                                "day"
                              )
                            )
                          )}
                        </div>
                      ) : (
                        <Typography.Text type="warning" style={{ fontSize: 20 }}>
                          {t("contract.helpers.chooseFixationAnniversaryDate")}
                        </Typography.Text>
                      )}
                    </div>
                    <div>
                      <Typography.Text type="secondary" italic>
                        {t("contract.helpers.contractTerminationLastDate")}
                      </Typography.Text>
                    </div>
                  </p>
                </Modal>
              </>
            ) : undefined}

            <ActionButton color="danger" size="middle" variant="outlined" icon="reload" onClick={handleFilterClear}>
              {t("common.resetFilter")}
            </ActionButton>
          </>
        }
      >
        <Form form={form} name="contractsFilterForm" layout="vertical">
          <Flex gap="middle" align="end">
            <div style={{ flex: colSpan }}>
              <Form.Item
                name="keyword"
                label={
                  <LabelWithPopover
                    label={t("contract.filter.keyword")}
                    popoverTitle={t("contract.filter.helpers.keywordPopoverTitle")}
                    popoverContent={
                      <>
                        <ul>
                          <li>{t("contract.filter.helpers.keywordPopoverSearchBy1")}</li>
                          <li>{t("contract.filter.helpers.keywordPopoverSearchBy2")}</li>
                          <li>{t("contract.filter.helpers.keywordPopoverSearchBy3")}</li>
                          <li>{t("contract.filter.helpers.keywordPopoverSearchBy4")}</li>
                        </ul>
                      </>
                    }
                  />
                }
                rules={[
                  validations.size(
                    validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                    validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                  )
                ]}
                initialValue={contractsPage.keyword}
              >
                <Input allowClear />
              </Form.Item>
            </div>
            <div style={{ flex: colSpan }}>
              <InstitutionTreeSelect
                formItemProps={{
                  name: "institutionIds",
                  label: t("contract.filter.institutions"),
                  initialValue: contractsPage.institutionIds || []
                }}
              />
            </div>
            <div style={{ flex: colSpan }}>
              <ProductTreeSelect
                formItemProps={{
                  name: "productIds",
                  label: t("contract.filter.products"),
                  initialValue: contractsPage.productIds || []
                }}
              />
            </div>
            <div style={{ flex: colSpan }}>
              <AgentSelect
                formItemProps={{
                  name: "gainerIds",
                  label: t("contract.filter.gainerIds"),
                  initialValue: contractsPage.gainerIds || []
                }}
                selectProps={{ mode: "multiple", maxTagCount: "responsive", allowClear: true }}
                optionsProps={{ extendedFilter: { form } }}
              />
            </div>
            <div style={{ flex: smallColSpan }}>
              <Form.Item
                name="statuses"
                label={t("contract.filter.statuses")}
                initialValue={contractsPage.statuses || []}
              >
                <Select
                  {...selectTagsStandardProps(contractStatusTMap)}
                  allowClear
                  mode="multiple"
                  maxTagCount="responsive"
                  tagRender={props => (
                    <ContractStatusTag
                      status={props.value}
                      closable={props.closable}
                      onClose={props.onClose}
                      label={props.label}
                    />
                  )}
                  options={Object.values(ContractStatus).map(status => ({
                    value: status,
                    label: <ContractStatusTag status={status} />
                  }))}
                />
              </Form.Item>
            </div>
            <div style={{ flex: smallColSpan }}>
              <Form.Item
                name="verificationStatuses"
                label={t("contract.filter.verificationStatus")}
                initialValue={contractsPage.verificationStatuses || []}
              >
                <Select<ContractVerificationStatus[]>
                  {...selectTagsStandardProps(contractVerificationStatusTMap)}
                  allowClear
                  mode="multiple"
                  maxTagCount="responsive"
                  tagRender={props => (
                    <ContractVerificationStatusTag
                      status={props.value}
                      closable={props.closable}
                      onClose={props.onClose}
                      label={props.label}
                    />
                  )}
                  options={Object.values(ContractVerificationStatus).map(status => ({
                    value: status,
                    label: <ContractVerificationStatusTag status={status} />
                  }))}
                />
              </Form.Item>
            </div>
            <div style={{ flex: 2 }}>
              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  icon={<AntIcon type="search" />}
                  onClick={handleFormSubmit}
                >
                  {t("contract.filter.submit")}
                </Button>
              </Form.Item>
            </div>
          </Flex>

          <Row gutter={rowGutter}>
            <Col span={24} className="extended-filter-action">
              <ActionTextIcon
                icon="edit"
                color="blue"
                text={t("contract.filter.extended")}
                onClick={() => setFilterModalOpen(true)}
              />
              {filterModalText && (
                <>
                  <span className="margin-left-tiny margin-right-tiny">{filterModalText}</span>
                  <ActionTextIcon
                    icon="delete"
                    color="red"
                    onClick={handleModalFilterClear}
                    text={t("common.cancel")}
                  />
                </>
              )}
            </Col>
          </Row>

          <Modal
            width={ModalSizes.MEDIUM}
            open={filterModalOpen}
            title={t("contract.filter.extended")}
            okText={t("contract.filter.submit")}
            okButtonProps={{ icon: <AntIcon type="search" /> }}
            cancelText={t("common.cancel")}
            maskClosable={false}
            forceRender
            onOk={handleFormSubmit}
            onCancel={handleModalCancel}
          >
            <Row gutter={rowGutter}>
              <Col span={12}>
                <AgentSelect
                  formItemProps={{
                    name: "signerIds",
                    label: t("contract.filter.signerIds"),
                    initialValue: contractsPage.signerIds || []
                  }}
                  selectProps={{
                    mode: "multiple",
                    maxTagCount: "responsive",
                    allowClear: true,
                    style: { width: 318 }
                  }}
                  optionsProps={{
                    filter: agent => agent.type !== AgentType.LEGAL,
                    extendedFilter: { form }
                  }}
                />
              </Col>

              <Col span={12}>
                <AgentSelect
                  formItemProps={{
                    name: "managerIds",
                    label: t("contract.filter.managerIds"),
                    initialValue: contractsPage.managerIds || []
                  }}
                  selectProps={{
                    mode: "multiple",
                    maxTagCount: "responsive",
                    allowClear: true,
                    style: { width: 318 }
                  }}
                  optionsProps={{
                    filter: agent => agent.type !== AgentType.LEGAL,
                    extendedFilter: { form }
                  }}
                />
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  name="createdAtRange"
                  label={t("contract.filter.createdAtRange")}
                  initialValue={
                    contractsPage.createdAtMin && contractsPage.createdAtMax
                      ? [contractsPage.createdAtMin, contractsPage.createdAtMax]
                      : undefined
                  }
                  {...rangePickerFormItemProps()}
                >
                  <DatePicker.RangePicker
                    format={getDatePickerFormat()}
                    disabledDate={current => disableDatePickerFuture(current)}
                    presets={datePickerDefaultRangesUntilToday}
                    placeholder={[t("common.from"), t("common.to")]}
                    style={{ width: 318 }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="mediationReportSignDateRange"
                  label={t("contract.filter.mediationReportSignDateRange")}
                  initialValue={
                    contractsPage.mediationReportSignDateMin && contractsPage.mediationReportSignDateMax
                      ? [contractsPage.mediationReportSignDateMin, contractsPage.mediationReportSignDateMax]
                      : undefined
                  }
                  {...rangePickerFormItemProps()}
                >
                  <DatePicker.RangePicker
                    format={getDatePickerFormat()}
                    presets={datePickerDefaultRanges}
                    placeholder={[t("common.from"), t("common.to")]}
                    style={{ width: 318 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  name="effectiveBeginningDateOrSignDateRange"
                  label={t("contract.filter.effectiveBeginningDateOrSignDateRange")}
                  initialValue={
                    contractsPage.effectiveBeginningDateOrSignDateMin &&
                    contractsPage.effectiveBeginningDateOrSignDateMax
                      ? [
                          contractsPage.effectiveBeginningDateOrSignDateMin,
                          contractsPage.effectiveBeginningDateOrSignDateMax
                        ]
                      : undefined
                  }
                  {...rangePickerFormItemProps()}
                >
                  <DatePicker.RangePicker
                    format={getDatePickerFormat()}
                    presets={datePickerDefaultRanges}
                    placeholder={[t("common.from"), t("common.to")]}
                    style={{ width: 318 }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="effectiveEndDateOrLoanMaturityDateRange"
                  label={t("contract.filter.effectiveEndDateOrLoanMaturityDateRange")}
                  initialValue={
                    contractsPage.effectiveEndDateOrLoanMaturityDateMin &&
                    contractsPage.effectiveEndDateOrLoanMaturityDateMax
                      ? [
                          contractsPage.effectiveEndDateOrLoanMaturityDateMin,
                          contractsPage.effectiveEndDateOrLoanMaturityDateMax
                        ]
                      : undefined
                  }
                  {...rangePickerFormItemProps()}
                >
                  <DatePicker.RangePicker
                    format={getDatePickerFormat()}
                    presets={datePickerDefaultRanges}
                    placeholder={[t("common.from"), t("common.to")]}
                    style={{ width: 318 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  name="cancellationDateRange"
                  label={t("contract.filter.cancellationDateRange")}
                  initialValue={
                    contractsPage.cancellationDateMin && contractsPage.cancellationDateMax
                      ? [contractsPage.cancellationDateMin, contractsPage.cancellationDateMax]
                      : undefined
                  }
                  {...rangePickerFormItemProps()}
                >
                  <DatePicker.RangePicker
                    format={getDatePickerFormat()}
                    presets={datePickerDefaultRanges}
                    placeholder={[t("common.from"), t("common.to")]}
                    style={{ width: 318 }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <AffiliatePartnerSelect
                  formItemProps={{
                    name: "affiliatePartnerIds",
                    label: t("contract.filter.affiliatePartners"),
                    initialValue: contractsPage.affiliatePartnerIds || []
                  }}
                  selectProps={{
                    mode: "multiple",
                    maxTagCount: "responsive",
                    allowClear: true,
                    style: { width: 318 }
                  }}
                />
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  name="transferredFromOtherBroker"
                  valuePropName="checked"
                  className="margin-top-medium"
                  rules={[validations.none]}
                  initialValue={!!contractsPage.transferredFromOtherBroker}
                >
                  <Checkbox>{t("contract.filter.transferredFromOtherBroker")}</Checkbox>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="transferredToOtherBrokerDateRange"
                  label={t("contract.filter.transferredToOtherBrokerDateRange")}
                  initialValue={
                    contractsPage.transferredToOtherBrokerDateMin && contractsPage.transferredToOtherBrokerDateMax
                      ? [contractsPage.transferredToOtherBrokerDateMin, contractsPage.transferredToOtherBrokerDateMax]
                      : undefined
                  }
                  {...rangePickerFormItemProps()}
                >
                  <DatePicker.RangePicker
                    format={getDatePickerFormat()}
                    presets={datePickerDefaultRanges}
                    placeholder={[t("common.from"), t("common.to")]}
                    style={{ width: 318 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={rowGutter}>
              <Col span={12}>
                <Form.Item
                  name="vehicleFirstRegistrationYearRange"
                  label={t("contract.filter.vehicleFirstRegistrationYearRange")}
                  initialValue={
                    contractsPage.vehicleFirstRegistrationYearMin && contractsPage.vehicleFirstRegistrationYearMax
                      ? [contractsPage.vehicleFirstRegistrationYearMin, contractsPage.vehicleFirstRegistrationYearMax]
                      : undefined
                  }
                  {...rangePickerFormItemProps("year")}
                >
                  <DatePicker.RangePicker
                    picker="year"
                    format="YYYY"
                    disabledDate={current => disableDatePickerFuture(current)}
                    defaultPickerValue={[dayjs().subtract(12, "year"), dayjs().subtract(6, "year")]}
                    placeholder={[t("common.from"), t("common.to")]}
                    style={{ width: 318 }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label={t("contract.filter.annualPremiumOrApprovedAmountRange")}>
                  <Row gutter={rowGutter}>
                    <Col span={12}>
                      <Form.Item
                        name="annualPremiumOrApprovedAmountMin"
                        rules={[validations.minNumber(1)]}
                        initialValue={contractsPage.annualPremiumOrApprovedAmountMin}
                      >
                        <InputNumber
                          {...inputNumberIntegerStandardProps}
                          addonAfter={<InputAddon type="euro" />}
                          placeholder={t("common.from")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, next) =>
                          prev.annualPremiumOrApprovedAmountMin !== next.annualPremiumOrApprovedAmountMin
                        }
                      >
                        {({ getFieldValue }) => {
                          const min = getFieldValue(["annualPremiumOrApprovedAmountMin"]) || 1;
                          return (
                            <Form.Item
                              name="annualPremiumOrApprovedAmountMax"
                              rules={[validations.minNumber(min)]}
                              initialValue={contractsPage.annualPremiumOrApprovedAmountMax}
                            >
                              <InputNumber
                                {...inputNumberIntegerStandardProps}
                                addonAfter={<InputAddon type="euro" />}
                                placeholder={t("common.to")}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Modal>
        </Form>
      </Card>
    </>
  );
};

export default ContractListFilterView;
